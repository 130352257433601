.App {
  display: flex;
  flex-wrap: wrap; /* This will space out the cards evenly */
  padding: 1rem; /* Adds some padding around the container */
}

@media (max-width: 768px) {
  .App {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  .card-container {
    width: 100%; /* Allow each card to take full width on smaller screens */
    margin: 0.5rem 0; /* Adjust margin for vertical stacking */
  }

  /* Adjust font sizes, paddings, or any other properties for mobile here */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
